import { PayoutStatus, SaleStatus, SaleType } from './performance';
import { Device } from './tracking';
export type ISOTimeRange = {
  start: string;
  end: string;
};

// @guide Add an Analytics field - 1.1 Define the new field. This string is used in the API to e.g. filter by it
export type AnalyticsField =
  | 'author'
  | 'device'
  | 'country'
  | 'page_view_id'
  | 'page_url'
  | 'page_url_origin'
  | 'channel_id'
  | 'integration_id'
  | 'referrer'
  | 'referrer_origin'
  | 'referrer_entry'
  | 'referrer_entry_origin'
  | 'link_id'
  | 'link_occ'
  | 'link_name'
  | 'link_url'
  | 'link_dest_url'
  | 'tags'
  | 'parent_tags'
  | 'pk'
  | 'sale_type'
  | 'sale_status'
  | 'payout_id'
  | 'payout_status'
  | 'utm_campaign'
  | 'utm_source'
  | 'utm_medium'
  | 'utm_term'
  | 'utm_content'
  | 'tracking_label'
  | 'tracking_id'
  | 'partner_product_name'
  | 'partner_product_id'
  | 'partner_product_category'
  | 'order_id'
  | 'page_data_01'
  | 'page_data_02'
  | 'page_data_03'
  | 'page_data_04'
  | 'page_data_05'
  | 'page_data_06'
  | 'page_data_07'
  | 'page_data_08'
  | 'page_data_09'
  | 'page_data_10'
  | 'page_data_11'
  | 'page_data_12'
  | 'page_data_13'
  | 'page_data_14'
  | 'page_data_15'
  | 'click_data_01'
  | 'click_data_02'
  | 'click_data_03'
  | 'click_data_04'
  | 'click_data_05'
  | 'click_data_06'
  | 'click_data_07'
  | 'click_data_08'
  | 'click_data_09'
  | 'click_data_10'
  | 'advertiser_id'
  | 'advertiser_name'
  | 'sub_id_01'
  | 'sub_id_02'
  | 'sub_id_03'
  | 'sub_id_04'
  | 'sub_id_05'
  | 'sub_id_06'
  | 'sub_id_07'
  | 'sub_id_08'
  | 'advertiser_sub_id_01'
  | 'advertiser_sub_id_02'
  | 'advertiser_sub_id_03'
  | 'advertiser_sub_id_04'
  | 'advertiser_sub_id_05'
  | 'seller'
  | 'marketplace'
  | 'brand'
  ;

export type CountUniqOfAnalyticsField = `count_uniq_${AnalyticsField}`
export type AggregateUniqOfAnalyticsField = `agg_uniq_${AnalyticsField}`
// and many more - TODO


export type SelectableFieldSales =
  | 'aov_net'
  | 'aov_gross'
  | 'epc_net'
  | 'avg_rate_net'
  | 'order_count_net'
  | 'order_count_gross'
  | 'commission_count_net'
  | 'commission_count_gross'
  | 'transaction_count_net'
  | 'transaction_count_gross'
  | 'final_count'
  | 'pending_count'
  | 'refunded_count'
  | 'canceled_count'
  | 'non_commissionable_count'
  | 'rejected_count'
  | 'unknown_count'
  | 'final_sum'
  | 'pending_sum'
  | 'refunded_sum'
  | 'canceled_sum'
  | 'non_commissionable_sum'
  | 'rejected_sum'
  | 'unknown_sum'
  | 'commission_sum_net'
  | 'commission_sum_gross'
  | 'commission_avg_net'
  | 'commission_avg_gross'
  | 'gmv_sum_net'
  | 'gmv_sum_gross'
  | 'quantity_net'
  | 'quantity_gross';

export type SelectableFieldPageViews = 'p' | 's' | 'v' | 'c' | 'ctr' | 'view_ratio' | 'page_last_modified';

export type SelectableFieldCrossCutting = 'rpm_net' | 'epc_net';

export type SelectableField =
  | SelectableFieldPageViews
  | SelectableFieldSales
  | SelectableFieldCrossCutting;

export type SelectableFieldComplete = SelectableField | CountUniqOfAnalyticsField | AggregateUniqOfAnalyticsField

export type AnalyticsFilterIn = {
  type?: 'value';
  field: AnalyticsField;
  condition: 'in' | 'not in';
  values: string[];
};

export type AnalyticsFilterLike = {
  type?: 'value';
  field: AnalyticsField;
  condition: 'like' | 'not like' | 'ilike' | 'not ilike';
  pattern: string
};

export type AnalyticsFilterLikeAny = {
  type?: 'value';
  field: AnalyticsField;
  condition: 'like any' | 'not like any' | 'ilike any' | 'not ilike any';
  patterns: string[]
};

export type AnalyticsFilterIsReference = {
  type: 'reference';
  field: AnalyticsField; // Actually just a subset of these - will tie down later
  condition: 'is' | 'is not';
  otherField: AnalyticsField; // Actually just a subset of these - will tie down later
};

export type AnalyticsFilter = AnalyticsFilterIn | AnalyticsFilterLike | AnalyticsFilterLikeAny | AnalyticsFilterIsReference;

export type AnalyticsOrderBy = {
  field: SelectableFieldComplete | AnalyticsField | 'interval';
  direction?: 'ASC' | 'DESC';
  emptyLast?: boolean;
};

export type AnalyticsIntervalUnit =  'hour' | 'minute' |'day' | 'week'| 'month' | 'quarter'  | 'year';

export const clickhouseIntervalReturnToMomentFormat = (unit: AnalyticsIntervalUnit): string => {
  switch (unit) {
    case 'week':
    case 'month':
    case 'quarter':
    case 'year':
      return 'YYYY-MM-DD';
    default:
      return 'YYYY-MM-DD HH:mm:ss'
  }
}

export type AnalyticsInterval = {
  unit: AnalyticsIntervalUnit
  value: number;
  tz: string;

  // When fill is true, then any accompanying order by clause *is ignored*
  // Ordering will be automatically handled to guarantee continuous time ranges.
  // The ordering will be: all groupBy fields, followed by the interval unit.
  fill?: boolean; // defaults to true!
}

export type AnaltyicsColumnTransformerIn = {
  field: AnalyticsField,
  mapTo: string,
  condition: 'in' | 'not in',
  values: string[]
}

export type AnaltyicsColumnTransformerLike = {
  field: AnalyticsField,
  mapTo: string,
  condition: 'like' | 'not like' | 'ilike' | 'not ilike',
  pattern: string,
}

export type AnaltyicsColumnTransformerLikeAny = {
  field: AnalyticsField,
  mapTo: string,
  condition: 'like any' | 'not like any' | 'ilike any' | 'not ilike any';
  patterns: string[]
}

export type AnalyticsColumnTransformer = AnaltyicsColumnTransformerIn | AnaltyicsColumnTransformerLike | AnaltyicsColumnTransformerLikeAny;

export type AnalyticsSearch = {
  pattern: string,
  in: AnalyticsField[]
}

export type AnalyticsSelectField = SelectableField | CountUniqOfAnalyticsField | AggregateUniqOfAnalyticsField;

export type AnalyticsQuery = {
  logId?: string;
  range: ISOTimeRange;
  compare?: {
    range: ISOTimeRange;
    // defaults to absolute-numbers
    // Supporting the others will be a nightmare, as it becomes *much* more difficult
    // to do sorting and pagination properly.
    // The issue is that with absolute numbers you can look at the normal range first,
    // while with all other modes you have to both ranges and can only determine later
    // which rows to pick
    mode?: 'absolute-numbers' | 'absolute-growth' | 'relative-growth';
  };
  interval?: AnalyticsInterval;
  filters?: AnalyticsFilter[];
  search?: AnalyticsSearch[],
  groupBy?: AnalyticsField[];
  columnTransformers?: AnalyticsColumnTransformer[],
  select: AnalyticsSelectField[]; // which columns to return - can improve query speed
  orderBy?: AnalyticsOrderBy[]; // column name plus direction, e.g. [{ field: 'c', direction: 'DESC' }]
  limitBy?: {
    // if limitBy is present, orderBy is required, and all fields limited by need to be present in groupBy
    offset?: number,
    limit: number,
    fields: AnalyticsField[]
  }
  paginate?: {
    // if paginate is present, orderBy is required!
    page: number;
    limit: number;
  };
};

export type AnalyticsResponseRow = {
  group: {
    [groupByKey in AnalyticsField]: string;
  } & { interval: string };
  data: {
    [k in SelectableField]?: number;
  } & {
    [k in CountUniqOfAnalyticsField]?: number
  } & {
    [k in AggregateUniqOfAnalyticsField]?: string[]

  };
};

export type AnalyticsResponseRowWithComparison = {
  group: {
    [groupByKey: string]: string;
  };
  data: {
    [k in SelectableField]?: { prev?: number; curr: number };
  } & {
    [k in CountUniqOfAnalyticsField]?: { prev?: number; curr: number };
  } & {
    [k in AggregateUniqOfAnalyticsField]?: { prev?: string[]; curr: string[] };
  };
};

export type AnalyticsResponse = {
  q: AnalyticsQuery;
  rows: AnalyticsResponseRowWithComparison[];
  time: number;
};

// This type has a lot of optional fields, because we will
// ask for fields selectively. A bunch of them should always be asked
// for for consistency
export type ClickhouseTransaction = {
  id: string,
  space_id: string,
  sale_id: string,
  order_id?: string,

  sale_date: string, // SQL timestamp format, as all other dates
  click_date?: string,
  sale_or_click_date?: string,
  completion_date?: string
  payout_date?: string,

  sale_type?: SaleType,
  sale_status?: Lowercase<SaleStatus>, // TODO type it out
  payout_status?: PayoutStatus,

  price?: number,
  revenue?: number,
  commission: number,
  comm_percent?: number,
  quantity?: number,

  page_url?: string,
  page_url_origin?: string,
  channel_id?: string,
  referrer?: string,
  referrer_origin?: string,
  referrer_entry?: string,
  referrer_entry_origin?: string,


  page_view_id?: string,
  link_id?: string,
  link_occ?: string, // format: 0001 etc
  label_rule_id?: string,
  tracking_id?: string,
  tracking_label?: string,

  advertiser_id?: string,
  advertiser_name?: string,
  pk?: string,

  partner_product_id?: string[],
  partner_product_name?: string[],
  coupon?: string[],

  device?: Device,
  country?: string

  author?: string[],


  sub_id_01?: string,
  sub_id_02?: string,
  sub_id_03?: string,
  sub_id_04?: string,
  sub_id_05?: string,
  sub_id_06?: string,
  sub_id_07?: string,
  sub_id_08?: string,
  advertiser_sub_id_01?: string,
  advertiser_sub_id_02?: string,
  advertiser_sub_id_03?: string,
  advertiser_sub_id_04?: string,
  advertiser_sub_id_05?: string,

  page_data_01?: string[],
  page_data_02?: string[],
  page_data_03?: string[],
  page_data_04?: string[],
  page_data_05?: string[],
  page_data_06?: string[],
  page_data_07?: string[],
  page_data_08?: string[],
  page_data_09?: string[],
  page_data_10?: string[],
  page_data_11?: string[],
  page_data_12?: string[],
  page_data_13?: string[],
  page_data_14?: string[],
  page_data_15?: string[],
  click_data_01?: string[],
  click_data_02?: string[],
  click_data_03?: string[],
  click_data_04?: string[],
  click_data_05?: string[],
  click_data_06?: string[],
  click_data_07?: string[],
  click_data_08?: string[],
  click_data_09?: string[],
  click_data_10?: string[],

  utm_campaign?: string[],
  utm_source?: string[],
  utm_medium?: string[],
  utm_term?: string[],
  utm_content?: string[],
}

export type TransactionsResponse = {
  q: TransactionsQuery,
  rows: ClickhouseTransaction[],
  time: number
}

type AdditionalTransactionsField =
   | 'id'
   | 'space_id'
   | 'sale_id'
   | 'comm_percent'
   | 'commission'
   | 'price'
   | 'revenue'
   | 'currency'
   | 'quantity'
   | 'click_date'
   | 'click_or_sale_date'
   | 'sale_date'
   | 'completion_date'
   | 'payout_date'
   | 'label_rule_id'
   | 'coupon'
   | 'sub_id_01'
   | 'sub_id_02'
   | 'sub_id_03'
   | 'sub_id_04'
   | 'sub_id_05'
   | 'sub_id_06'
   | 'sub_id_07'
   | 'sub_id_08'
   | 'advertiser_sub_id_01'
   | 'advertiser_sub_id_02'
   | 'advertiser_sub_id_03'
   | 'advertiser_sub_id_04'
   | 'advertiser_sub_id_05'
   // for exporting
   | 'r_currency'
   | 'r_commission'
   | 'r_price'
   | 'r_revenue'
   | 'o_currency'
   | 'o_commission'
   | 'o_price'
   | 'o_revenue'
   ;

export type TransactionsField = AnalyticsField | AdditionalTransactionsField;

export type TransactionsOrderBy = {
  field: TransactionsField,
  direction?: 'ASC' | 'DESC';
  emptyLast?: boolean;
};

export type TransactionsQuery = {
  logId?: string;
  select: TransactionsField[];
  filters?: AnalyticsFilter[];
  search?: AnalyticsSearch[];
  columnTransformers?: AnalyticsColumnTransformer[],
  range: ISOTimeRange;
  orderBy?: TransactionsOrderBy[];
  paginate?: {
    // if paginate is present, orderBy is required!
    page: number;
    limit: number;
  };
};
